<template>
<!-- 设置页面 -->
  <div>
    <!-- <van-icon name="arrow-left" class="back" @click="back" />
    <span class="setting">设置</span>

    <van-divider class="divider" /> -->

    <div class="center">
      <van-cell v-for="(item, index) in actionList" :key="index">
        <template #title>
          <span class="center-title">{{ item.title }}</span>
        </template>
        <template #right-icon>
          <img v-if="index == 0" :src="item.iconUrl" class="center-icon" />
          <span v-else class="center-name">{{ item.name }}</span>
        </template>
      </van-cell>
    </div>
    <div class="logout" @click="$utils.logout">
      <p>退出登录</p>
    </div>
  </div>
</template>
<script>
import { getUserInfo } from '@/api/my/my'
export default {
  data() {
    return {
      actionList: [
        {
          title: '头像',
          iconUrl:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psy8b2t3sr3tkx4y8swf5s6pdkfjvbi6um456a9445-84b1-4014-8273-3ba1a6df31ed',
        },
        {
          title: '用户姓名',
          name: '知识库',
        },
        {
          title: '邮箱',
          name: 'xgxy@honjomed.com',
        },
      ],
    }
  },
  created() {
    getUserInfo().then((res) => {
      this.actionList[0].iconUrl = res.result.avatar
      this.actionList[1].name = res.result.realname
    })
  },
  computed: {},
  methods: {
    back() {
      this.$router.push('/my')
    },
  },
}
</script>
<style lang="less" scoped>
.back {
  font-size: 4rem;
  margin-left: 4rem;
  margin-top: 9rem;
}
.setting {
  font-size: 4rem;
  margin-left: 28rem;
  font-weight: 500;
  color: #000000;
}
.center {
  margin-left: 3%;
  width: 94%;
  border-radius: 32px;
  margin-top: 5rem;
  box-shadow: 1px 1px 1px 1px rgba(3, 3, 3, 0.05),
    -2px -2px 1px 1px rgba(3, 3, 3, 0.05);
  &-title {
    line-height: 160px;
    font-weight: 400;
    color: #3e3a39;
    font-size: 3.5rem;
  }
  &-icon {
    width: 191px;
    border-radius: 50%;
    vertical-align: bottom;
  }
  &-name {
    font-size: 3.5rem;
    font-weight: 400;
    color: #b5b5b6;
  }
}
.logout {
  box-shadow: 1px 1px 1px 1px rgba(3, 3, 3, 0.05),
    -2px -2px 1px 1px rgba(3, 3, 3, 0.05);
  text-align: center;
  border-radius: 32px;
  width: 94%;
  margin-left: 3%;
  margin-top: 6rem;
  p {
    font-size: 56px;
    font-weight: 400;
    color: #ea5514;
    line-height: 8rem;
  }
}
::v-deep .van-cell {
  align-items: center;
}
</style>
